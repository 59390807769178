<template>
  <label v-if="label && label.length > 0" :for="elId" class="form-label" :class="validationClass(props.modelValue)">
    {{ label }}
    <span class="text-danger" v-show="isRequired">*</span>
  </label>
  <div class="invalid-feedback d-inline ms-1 fst-italic">
    {{ validationErrorMessage(modelValue) }}
  </div>
  <input
    class="form-control"
    :type="type"
    v-model="props.modelValue.$model"
    :placeholder="placeholder"
    :class="validationClass(props.modelValue)"
    :id="elId"
    :step="props.step"
    @input="delayTouch(props.modelValue)"
    trim
    @change="selectChanged"
  />
  <!-- <div>props.modelValue:{{ elId }}</div> -->
</template>

<script>
import { computed } from "vue";
import * as _validation from "@/components/Library/Validation/validation";
const touchMap = new WeakMap();
export default {
  name: "cValidatedInput",
  props: {
    modelValue: { default: null, type: Object },
    type: { default: "text", type: String },
    label: { default: "", type: String },
    placeholder: { default: "", type: String },
    elementId: { default: "", type: String },
    step: { default: 0, type: Number },
    validationDelay: {
      default: 2000,
      type: Number,
    },
  },
  emits: ["input"],
  setup(props, context) {
    const delayTouch = function (v) {
      v.$reset();
      if (touchMap.has(v)) {
        clearTimeout(touchMap.get(v));
      }
      touchMap.set(v, setTimeout(v.$touch, props.validationDelay));
    };

    const elId = computed(() => {
      if (props.elementId.length > 0) return props.elementId;
      let result = "";
      let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let charactersLength = characters.length;
      for (var i = 0; i < 8; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    });

    const selectChanged = () => {
      context.emit("selectChanged", props.modelValue.$model);
    };

    const isRequired = computed(() => {
      return props.modelValue.required;
    });

    const validationErrorMessage = function (p) {
      return _validation.validationErrorMessage(p);
    };
    const validationClass = function (p) {
      return _validation.validationClass(p);
    };

    return { delayTouch, elId, isRequired, props, selectChanged, validationErrorMessage, validationClass };
  },
};
</script>

<style></style>
