import store from "@/store";
import router from "@/router";
import * as _config from "@/config";
import * as _utility from "@/utility";

//https://javascript.info/fetch
//https://dev.to/effingkay/cors-preflighted-requests--options-method-3024
export function fetchGet(url, data, showWait) {
  return new Promise((resolve) => {
    //automatically append the token if it exists..
    let token = store.state.user.token;
    data.Token = token;
    data.AppId = store.getters.appId;
    data = _utility.toTitleCaseObject(data);
    //console.info("fetchGet", url, data);
    //build the querystring
    let queryString = "";
    for (var key in data) {
      if (data.hasOwnProperty(key)) {
        if (data[key] && typeof data[key] === "object") {
          data[key].forEach((d) => {
            queryString += key + "=" + d + "&";
          });
        } else queryString += key + "=" + data[key] + "&";
      }
    }
    if (queryString.length > 0) {
      if (queryString.substr(queryString.lastIndexOf("&")) === "&") queryString = queryString.substr(0, queryString.lastIndexOf("&"));
      url += "?" + queryString;
    }
    if (showWait) store.commit("showWait", true);

    fetch(url, {
      method: "GET",
      headers: {
        //Authorization: "Bearer " + token,
      },
    })
      .then((data) => data.json())
      .then((response) => {
        if (showWait) store.commit("showWait", false);
        if (!response.success) {
          showError(response);
        }
        if (response.isUpdating && router.currentRoute.name !== "update") {
          router.push("/update");
        }
        if (response.tokenExpired) {
          store.dispatch("logOut").then((r) => {
            router.push("/login");
          });
        }
        return resolve(response);
      })
      .catch((error) => {
        if (showWait) store.commit("showWait", false);
        let response = {
          success: false,
          failureInformation: "There was a problem calling the GET service",
          displayStyle: 3,
        };
        showError(response);
        console.error(response.failureInformation);
        console.error(error);
        return resolve(response);
      });
  });
}

export function fetchPost(url, data, showWait) {
  return new Promise((resolve, reject) => {
    //automatically append the token if it exists..
    let token = store.state.user.token;
    data.Token = token;
    data.AppId = store.getters.appId;
    data = _utility.toTitleCaseObject(data);
    //console.info("fetchPost", url, data);
    //If we use .Net WebAPI we need to put data in a form,
    //but if we use CODE Framework WebAPI we need to send
    //data as a JSON payload..
    //let formData = createFormFromData(data);
    if (showWait) store.commit("showWait", true);
    fetch(url, {
      method: "POST",
      headers: {
        //NOTE: Authorization and Content-Type trigger a CORS pre-flight check, which doesn't allow authorization...
        //So to make it work we have to revert to passing token on querystring :(
        //"Content-Type": "application/json",
        //Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    })
      .then((data) => data.json())
      .then((response) => {
        if (showWait) store.commit("showWait", false);
        if (!response.success) {
          showError(response);
        }
        if (response.isUpdating && router.currentRoute.name !== "update") {
          router.push("/update");
        }
        if (response.tokenExpired) {
          store.dispatch("logOut").then((r) => {
            router.push("/login");
          });
        }
        return resolve(response);
      })
      .catch((error) => {
        if (showWait) store.commit("showWait", false);
        let response = {
          success: false,
          failureInformation: "There was a problem calling the GET service",
          displayStyle: 3,
        };
        showError(response);
        console.error(response.failureInformation);
        console.error(error);
        return reject(response);
      });
  });
}

function showError(response) {
  c8.addToast({
    title: "Service Message",
    body: response.failureInformation,
    style: response.success ? "warning" : "danger",
  });
}

//Nested form data requires recursive processing...
//this was found at https://gist.github.com/ghinda/8442a57f22099bdb2e34
//and works for at least one level of nesting...
function createFormFromData(obj, form, namespace) {
  let fd = form || new FormData();
  let formKey;

  for (let property in obj) {
    if (obj.hasOwnProperty(property) && obj[property]) {
      if (namespace) {
        formKey = namespace + "[" + property + "]";
      } else {
        formKey = property;
      }

      // if the property is an object, but not a File, use recursivity.
      if (obj[property] instanceof Date) {
        fd.append(formKey, obj[property].toISOString());
      } else if (typeof obj[property] === "object" && !(obj[property] instanceof File)) {
        createFormFromData(obj[property], fd, formKey);
      } else {
        // if it's a string or a File object
        fd.append(formKey, obj[property]);
      }
    }
  }

  return fd;
}
