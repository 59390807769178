<template>
  <transition name="fade">
    <div v-if="$store.state.showWait" class="wait-indicator">
      <div class="spinner-border text-primary" style="width: 3rem; height: 3rem" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <br />Please wait...
    </div>
  </transition>
  <cToast />
  <cTaskStatusAlert />
  <!-- <cTaskStatusIcon /> -->
  <cNav />
  <cSidebar />
  <main class="flex-shrink-0 mb-5">
    <div class="container" @click="closeSideBar" style="padding: 10px">
      <router-view />
    </div>
  </main>
  <cFooter />
</template>

<script>
import { inject, onMounted, ref } from "vue";

import cFooter from "@/components/App/cFooter";
import cNav from "@/components/App/cNav";
import cSidebar from "@/components/App/cSidebar";
import cTaskStatusAlert from "@/components/Library/TaskStatus/cTaskStatusAlert";
import cTaskStatusIcon from "@/components/Library/TaskStatus/cTaskStatusIcon";
import cToast from "@/components/App/cToast";

export default {
  components: { cFooter, cNav, cSidebar, cTaskStatusAlert, cTaskStatusIcon, cToast },
  setup() {
    const closeSideBar = function () {
      c8.removeClassById("side-bar", "show");
    };

    const parsedText = ref("");
    const parseKeyStrokes = (event) => {
      if (!event || !event.key) return;
      //const charList = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      // we are only interested in numbers…
      // const charList = "0123456789";
      // const key = event.key.toUpperCase();
      // if (charList.indexOf(key) === -1) return;
      const key = event.key.toUpperCase();

      //----------------------------------------------------------------------
      // Setting a time out will automatically clear the parsing buffer.
      // Setting the time out to 300ms seems to allow enough time for the
      // scanner to input all the numbers, anything less clears the buffer before
      // all of the numbers are processed
      //----------------------------------------------------------------------
      // const delay = 300;
      // setTimeout(() => {
      //   parsedText.value = "";
      // }, delay);
      //----------------------------------------------------------------------

      // Append current key to buffer
      parsedText.value += key;

      //console.info("parseKeyStrokes value, length ", parsedText.value, parsedText.value.length);
      //All CTRL keys are 8 characters long (CONTROLx where x is the key)
      if (
        parsedText.value.indexOf("CONTROLD") > -1 || //Add
        parsedText.value.indexOf("CONTROLE") > -1 || //Delete
        parsedText.value.indexOf("CONTROLL") > -1 || //All
        parsedText.value.indexOf("CONTROLU") > -1 //Submit
      ) {
        c8.eventBus.emit("controlkey", parsedText.value);
        parsedText.value = "";
        return false;
      }
      return true;
    };

    onMounted(() => {
      // //must be key down, not key up...
      // document.onkeydown = function (evt) {
      //   evt = evt || window.event;
      //   return parseKeyStrokes(evt);
      // };
    });

    return { closeSideBar };
  },
};
</script>

<style></style>
