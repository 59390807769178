export default [
  {
    path: "/clientrates",
    name: "clientrates",
    component: () => import(/* webpackChunkName: "admin" */ "../views/admin/vClientRates.vue"),
    meta: { title: "Client Rates", auth: true, roles: [20] },
  },
  {
    path: "/invoicing",
    name: "invoicing",
    component: () => import(/* webpackChunkName: "admin" */ "../views/admin/vInvoicing.vue"),
    meta: { title: "Invoicing", auth: true, roles: [20] },
  },
  {
    path: "/invoicingbydate",
    name: "invoicingbydate",
    component: () => import(/* webpackChunkName: "admin" */ "../views/admin/vInvoicingByDate.vue"),
    meta: { title: "Invoicing By Date", auth: true, roles: [20] },
  },
  {
    path: "/adminticketedit",
    name: "adminticketedit",
    component: () => import(/* webpackChunkName: "admin" */ "../views/admin/vAdminTicketEdit.vue"),
    meta: { title: "Admin Ticket Edit", auth: true, roles: [20] },
  },
  {
    path: "/ticketeditbyproject",
    name: "ticketeditbyproject",
    component: () => import(/* webpackChunkName: "admin" */ "../views/admin/vTicketEditByProject.vue"),
    meta: { title: "Ticket Edit By Project", auth: true, roles: [20] },
  },
  {
    path: "/expensecheck",
    name: "expensecheck",
    component: () => import(/* webpackChunkName: "admin" */ "../views/admin/vExpenseCheck.vue"),
    meta: { title: "Expense Check", auth: true, roles: [20] },
  },
];
