<template>
  <div id="side-bar" class="side-bar bg-light shadow-sm">
    <div class="top-menu scroll-style">
      <ul class="parent-menu">
        <li v-for="(i, index) in navigationItems" v-show="isInRole(i)" :key="'sb-' + index" :class="{ hover: i.isOpen }">
          <span v-if="i.routeName">
            <router-link :to="getLink(i)">
              <div>{{ i.display }}</div>
            </router-link>
          </span>
          <span v-else>
            <div class="menu-item" @click="i.isOpen = !i.isOpen">
              <div class="icon-menu me-2">
                <i class="bi bi-chevron-double-down" v-show="!i.isOpen"></i>
                <i class="bi bi-chevron-double-up" v-show="i.isOpen"></i>
              </div>
              {{ i.display }}
            </div>
          </span>
          <div class="child-menu">
            <ul v-if="i.children.length > 0">
              <li v-for="(c, index) in i.children" v-show="isInRole(c)" :key="'sbc-' + index">
                <router-link :to="getLink(c)">
                  <div>{{ c.display }}</div>
                </router-link>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
    <div class="bottom-menu">
      <ul class="parent-menu">
        <li>
          <a href="https://docs.eps-office.com/Clockwork/LogginOn" target="_doc"> <i class="bi bi-info-circle"></i> Documentation </a>
          <a v-show="store.state.user.role == 20" href="https://eps-software.visualstudio.com/_git/EPSDocs?path=/Clockwork/LoggingOn.md&version=GBmaster&_a=contents" target="_docedit">
            <i class="bi bi-pencil" title="Edit Documentation"></i
          ></a>
        </li>
        <li v-if="store.state.user.email">
          <router-link to="/profile">
            <div><i class="bi bi-person"></i>Profile</div>
          </router-link>
        </li>
        <li v-if="store.state.user.email">
          <button class="btn btn-link" @click="logOut"><i class="bi bi-unlock"></i> Log Out</button>
        </li>
        <li v-if="!store.state.user.email">
          <router-link to="/login">
            <div><i class="bi bi-lock"></i> Log In</div>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { inject, ref, onMounted, watch } from "vue";
import router from "@/router";
import * as _utility from "@/utility";

import * as _securityService from "@/services/securityService";

export default {
  setup() {
    const store = inject("store");
    const navigationItems = ref([
      // {
      //   display: "Home",
      //   routeName: "home",
      //   parameterId: null,
      //   parameter: null,
      //   isOpen: false,
      //   roles: [0, 10, 20],
      //   children: [],
      // },
      {
        display: "Tickets",
        routeName: "tickets",
        parameterId: null,
        parameter: null,
        isOpen: false,
        roles: [0, 10, 20],
        children: [],
      },
      {
        display: "Ticket Report",
        routeName: "userticketreport",
        parameterId: null,
        parameter: null,
        isOpen: false,
        roles: [0, 10, 20],
        children: [],
      },
      {
        display: "Projects",
        routeName: "projects",
        parameterId: null,
        parameter: null,
        isOpen: false,
        roles: [0, 10, 20],
        children: [],
      },
      {
        display: "Expenses",
        routeName: "expenses",
        parameterId: null,
        parameter: null,
        isOpen: false,
        roles: [0, 10, 20],
        children: [],
      },
      {
        display: "Admin",
        routeName: null,
        parameterId: null,
        parameter: null,
        isOpen: false,
        roles: [10, 20],
        children: [
          {
            display: "Areas",
            routeName: "areas",
            parameterId: null,
            parameter: null,
            isOpen: false,
            roles: [10, 20],
            children: [],
          },
          {
            display: "Tasks",
            routeName: "tasks",
            parameterId: null,
            parameter: null,
            isOpen: false,
            roles: [10, 20],
            children: [],
          },
          {
            display: "Reports",
            routeName: "adminreports",
            parameterId: null,
            parameter: null,
            isOpen: false,
            roles: [10, 20],
          },
          {
            display: "Clients",
            routeName: "clientlist",
            parameterId: null,
            parameter: null,
            isOpen: false,
            roles: [20],
          },
          {
            display: "Users",
            routeName: "userlist",
            parameterId: null,
            parameter: null,
            isOpen: false,
            roles: [10, 20],
          },
          {
            display: "Managers",
            routeName: "managerlist",
            parameterId: null,
            parameter: null,
            isOpen: false,
            roles: [10, 20],
          },
          {
            display: "Ticket Edit By Project",
            routeName: "ticketeditbyproject",
            parameterId: null,
            parameter: null,
            isOpen: false,
            roles: [10, 20],
          },
        ],
      },
      {
        display: "Invoicing",
        routeName: null,
        parameterId: null,
        parameter: null,
        isOpen: false,
        roles: [20],
        children: [
          {
            display: "Client Rates",
            routeName: "clientrates",
            parameterId: null,
            parameter: null,
            isOpen: false,
            roles: [20],
          },
          {
            display: "Invoicing",
            routeName: "invoicing",
            parameterId: null,
            parameter: null,
            isOpen: false,
            roles: [20],
          },
          {
            display: "Invoicing by Date",
            routeName: "invoicingbydate",
            parameterId: null,
            parameter: null,
            isOpen: false,
            roles: [20],
          },
          {
            display: "Admin Ticket Edit",
            routeName: "adminticketedit",
            parameterId: null,
            parameter: null,
            isOpen: false,
            roles: [20],
          },
          {
            display: "Expense Check",
            routeName: "expensecheck",
            parameterId: null,
            parameter: null,
            isOpen: false,
            roles: [20],
          },
        ],
      },
    ]);

    const getLink = function (item) {
      if (item.routeName === null) return "";
      if (item.url && item.url.length > 0) return item.url;
      let name = item.routeName;
      let id = item.parameterId;
      let value = item.parameter;
      if (item.parameterId != null) {
        return { name: name, params: { id: value } };
      } else return { name: name };
    };

    const isInRole = (menuItem) => {
      return _utility.isInRole(menuItem.roles);
    };

    const load = () => {
      let data = _utility.retrieveSearch("navigationItems");
      if (data) {
        let items = JSON.parse(data);
        items.forEach((i) => {
          let item = navigationItems.value.find((n) => n.display === i.display);
          if (item) item.isOpen = i.isOpen;
        });
      }
    };

    const currentRoute = router;
    const logOut = function () {
      _securityService.logOut().then((response) => {
        if (response.success) {
          store.dispatch("logOut").then((r) => {
            currentRoute.push("/login");
          });
        }
      });
    };

    const showToast = function () {
      c8.addToast();
      c8.addToast({
        title: "Good Toast",
        body: "<b><i>This</i></b> is how you do it!",
        style: "success",
      });
    };

    watch(
      () => navigationItems,
      (newValue, oldValue) => {
        let data = [];
        newValue.value.forEach((i) => {
          if (!i.routeName) {
            //console.log("watch|navigationItems|", i.display, i.isOpen);
            data.push({ display: i.display, isOpen: i.isOpen });
          }
        });
        _utility.storeSearch("navigationItems", JSON.stringify(data), true);
      },
      { deep: true }
    );

    onMounted(() => {
      load();
    });

    return { navigationItems, getLink, isInRole, logOut, showToast, store };
  },
};
</script>

<style scoped>
.icon-menu {
  position: absolute;
  right: 0;
}
.top-menu {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: calc(100vh - 218px);
  overflow-x: hidden;
  overflow-y: auto;
}
.bottom-menu {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.075);
}
.bottom-menu ul li i {
  margin-right: 5px;
}
.menu-item {
  color: var(--bs-primary);
  padding-left: 10px;
  height: 34px;
  line-height: 34px;
}
.menu-item:hover,
.hover .menu-item,
.hover .menu-item .bi {
  color: var(--bs-white);
  border-bottom: 1px solid var(--bs-primary);
}
.menu-item .bi {
  border: none !important;
}
.child-menu {
  max-height: 0;
  transition: max-height 0.25s ease-out;
  overflow: hidden;
}
.hover .child-menu {
  max-height: 600px;
  transition: max-height 0.4s ease-in;
}

.side-bar .child-menu ul li {
  background-color: rgb(223, 223, 223) !important;
  color: var(--bs-primary);
}

.side-bar .child-menu ul li:hover,
.side-bar .child-menu ul li:hover a,
.side-bar .child-menu ul li:hover button.btn-link {
  background-color: rgb(160, 160, 160) !important;
  color: var(--bs-white);
}
.side-bar .child-menu ul li,
.side-bar .child-menu ul li a,
.side-bar .child-menu ul li button.btn-link {
  color: var(--bs-primary);
}
.side-bar .child-menu ul li a.router-link-active {
  color: var(--bs-dark);
  font-weight: bold;
}
.bottom-menu i.bi.bi-info-circle,
.bottom-menu i.bi.bi-pencil {
  position: relative;
  left: 7px;
  top: 4px;
  margin-right: 9px;
}
</style>
