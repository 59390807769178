import { createStore } from "vuex";
import * as _config from "@/config";
import * as _utility from "@/utility";
import "@/library/c8.js";

const _defaultUser = { id: "", name: "", email: null, token: null, role: -1, environment: "" };
//let ls = localStorage.getItem(_config.storageAppIdKey);
//let _appId = ls != null ? ls : c8.newGuid();

export default createStore({
  state: {
    appId: null,

    user: { ..._defaultUser },
    showWait: false,
    uploadPercent: 0,
    projects: [],
  },
  mutations: {
    showWait(state, value) {
      state.showWait = value;
    },
    updateUploadPercent(state, value) {
      state.uploadPercent = value;
    },
  },
  actions: {
    logIn({ state }, info) {
      return new Promise((resolve) => {
        if (info == null || info.length == 0) {
          resolve(false);
        }
        state.user = {
          id: info.id,
          email: info.email,
          token: info.token,
          name: info.name,
          role: info.role,
          environment: info.environment,
        };
        if (info.rememberMe) {
          localStorage.setItem(_config.storageUserKey, JSON.stringify(state.user));
          sessionStorage.removeItem(_config.storageUserKey);
        } else {
          sessionStorage.setItem(_config.storageUserKey, JSON.stringify(state.user));
          localStorage.removeItem(_config.storageUserKey);
        }
        resolve(true);
      });
    },
    logOut({ state }) {
      localStorage.removeItem(_config.storageUserKey);
      sessionStorage.removeItem(_config.storageUserKey);
      state.user = { ..._defaultUser };
    },

    loadProjects({ state }, projects) {
      state.projects = projects;
    },

    loadStore({ state }) {
      return new Promise((resolve) => {
        let ls = localStorage.getItem(_config.storageUserKey);
        if (ls == null) ls = sessionStorage.getItem(_config.storageUserKey);
        if (ls != null) {
          state.user = JSON.parse(ls);
          resolve(true);
        } else resolve(false);
      });
    },
    isAuthenticated({ state }) {
      return new Promise((resolve) => {
        if (state.user.token !== null) {
          resolve(true);
        } else {
          let ls = localStorage.getItem(_config.storageUserKey);
          if (ls == null) ls = sessionStorage.getItem(_config.storageUserKey);
          if (ls != null) {
            state.user = JSON.parse(ls);
            resolve(state.user.token != null);
          } else resolve(false);
        }
      });
    },
  },
  getters: {
    appId: (state) => {
      if (state.appId != null) {
        return state.appId;
      } else {
        let ls = localStorage.getItem(_config.storageAppIdKey);
        if (ls != null) {
          state.appId = ls;
          return ls;
        } else {
          const id = _utility.newGuid();
          localStorage.setItem(_config.storageAppIdKey, id);
          state.appId = id;
          return id;
        }
      }
    },
    projects: (state) => {
      return state.projects;
    },
    user: (state) => {
      return state.user;
    },
    userRole: (state) => {
      return state.user.role;
    },
    uploadPercent: (state) => state.uploadPercent,
  },
  modules: {},
});
