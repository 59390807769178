import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import * as _utility from "@/utility";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./css/style.css";
import "./css/transitions.css";
import "./library/c8.js";
import dayjs from "dayjs";

//mitt gives us an event bus
import eventBus from "./eventBus.js";

const app = createApp(App);
app.use(store);
app.use(router);
//app.provide("store", store);
app.config.globalProperties.eventBus = eventBus;
app.config.globalProperties.version = require("../package.json").version || 0;
app.mount("#app");
