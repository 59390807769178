import store from "../store";
import * as _core from "./coreService";
import * as _config from "@/config";
import * as _device from "@/utility/device";

export function confirmEmail(email, code) {
  return new Promise((resolve) => {
    let url = _config.urlSecurityService + "ConfirmEmail/";
    let data = { Email: email, AuthorizationCode: code };
    _core.fetchPost(url, data, true).then((response) => {
      resolve(response);
    });
  });
}

export function getRoles() {
  return new Promise((resolve) => {
    let url = _config.urlSecurityService + "GetRoles/";
    let data = {};
    _core.fetchGet(url, data, true).then((response) => {
      resolve(response);
    });
  });
}
export function isEmailUsed(email, userId) {
  return new Promise((resolve) => {
    let url = _config.urlSecurityService + "IsEmailUsed/";
    let data = { Email: email, UserId: userId };
    _core.fetchGet(url, data, true).then((response) => {
      resolve(response);
    });
  });
}

export function logIn(email, password, rememberMe) {
  return new Promise((resolve) => {
    let url = _config.urlSecurityService + "AuthenticateUser/";
    let data = { Email: email, Password: password, RememberMe: rememberMe };
    data.DeviceInfo = _device.device.getOsAndBrowser();
    //console.log(JSON.stringify(_device.device.getOsAndBrowser()));
    _core.fetchPost(url, data, true).then((response) => {
      if (response.success) {
        store
          .dispatch("logIn", {
            id: response.id,
            email: response.email,
            token: response.token,
            name: response.name,
            role: response.role,
            rememberMe: rememberMe,
            environment: response.environment,
          })
          .then((dispatchSuccess) => {
            resolve(response);
          });
      } else {
        resolve(response);
      }
    });
  });
}

export function logOut() {
  return new Promise((resolve) => {
    let url = _config.urlSecurityService + "LogOut/";
    let data = {};
    _core.fetchPost(url, data, true).then((response) => {
      store.dispatch("logOut").then((dispatchSuccess) => {});
      resolve(response);
    });
  });
}

export function passwordRecovery(email) {
  return new Promise((resolve) => {
    let url = _config.urlSecurityService + "ForgotPassword/";
    let data = { Email: email };
    _core.fetchPost(url, data, true).then((response) => {
      resolve(response);
    });
  });
}

export function passwordReset(email, password, code) {
  return new Promise((resolve) => {
    let url = _config.urlSecurityService + "ResetPassword/";
    let data = { Email: email, NewPassword: password, Code: code };
    _core.fetchPost(url, data, true).then((response) => {
      resolve(response);
    });
  });
}

export function passwordResetByUserId(userId, oldPassword, newPassword) {
  return new Promise((resolve) => {
    let url = _config.urlSecurityService + "PasswordResetByUserId/";
    let data = { UserId: userId, OldPassword: oldPassword, NewPassword: newPassword };
    _core.fetchPost(url, data, true).then((response) => {
      resolve(response);
    });
  });
}
