import eventBus from "../eventBus.js";
(function(window) {
	function c8Library() {
		var _c8Object = {};

		_c8Object.eventBus = eventBus;

		_c8Object.addToast = function(toast) {
			eventBus.emit("addToast", toast);
		};

		_c8Object.emptyGuid = "00000000-0000-0000-0000-000000000000";

		_c8Object.newGuid = function() {
			return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
				var r = (Math.random() * 16) | 0,
					v = c === "x" ? r : (r & 0x3) | 0x8;
				return v.toString(16);
			});
		};

		_c8Object.addClassById = function(elId, className) {
			document.getElementById(elId).classList.add(className);
		};

		_c8Object.removeClassById = function(elId, className) {
			document.getElementById(elId).classList.remove(className);
		};

		_c8Object.removeElementById = function(elId) {
			document.getElementById(elId).remove();
		};

		_c8Object.toggleClassById = function(elId, className) {
			document.getElementById(elId).classList.toggle(className);
		};

		return _c8Object;
	}

	if (typeof c8 === "undefined") {
		window.c8 = c8Library();
	}
})(window);
