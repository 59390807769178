<template>
  <div aria-live="polite" aria-atomic="true" class="toast-locator">
    <!-- Position it: -->
    <!-- - `.toast-container` for spacing between toasts -->
    <!-- - `.position-absolute`, `top-0` & `end-0` to position the toasts in the upper right corner -->
    <!-- - `.p-3` to prevent the toasts from sticking to the edge of the container  -->
    <div class="toast-container position-fixed bottom-0 start-0 p-3">
      <!-- Then put toasts within -->
      <div v-for="t in toasts" :key="t.id" class="toast fade" :class="{ show: t.isShown }" :id="t.id" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header bg-light">
          <strong class="me-auto">{{ t.title }}</strong>
          <small class="text-muted">{{ t.timeIssued }}</small>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close" @click="deleteToast(t)"></button>
        </div>
        <div class="toast-body" :class="getStyle(t)" v-html="t.body"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import dayjs from "dayjs";

const _defaultToast = {
  id: "123",
  title: "Title text..",
  body: "Body text...",
  style: "info",
  timeIssued: null,
  duration: 5,
  isShown: true,
};
//https://stackoverflow.com/questions/63471824/vue-js-3-event-bus
export default {
  setup() {
    c8.eventBus.off("addToast");
    c8.eventBus.on("addToast", (e) => addToast(e));

    const getStyle = function (t) {
      return {
        "alert alert-info": t.style === "info",
        "alert alert-success": t.style === "success",
        "alert alert-warning": t.style === "warning",
        "alert alert-danger": t.style === "danger",
      };
    };

    const getTime = function (date) {
      if (!date) date = dayjs();
      return date.format("h:mm:ss a");
    };
    const toasts = ref([]);

    const addToast = function (toast) {
      let t = {};
      if (!toast) {
        t = {
          ..._defaultToast,
          id: c8.newGuid(),
          title: "Toast Error",
          body: `You must supply a toast object! A minimum of:<br>
            <code>{title:'', body:''}</code><br>Optionally:<br>
            <code>style: 'info(default)/success/warning/danger'</code><br>
            <code>duration: int (5s default, 0=infinite)</code>`,
          timeIssued: getTime(),
          style: "danger",
          duration: null,
        };
      } else {
        t = {
          ..._defaultToast,
          id: c8.newGuid(),
          title: toast.title,
          body: toast.body,
          timeIssued: getTime(),
        };
        if (toast.style) t.style = toast.style;
        if (toast.duration !== null && typeof toast.duration === "number") t.duration = toast.duration;
      }
      toasts.value.push(t);
      if (t.duration > 0)
        setTimeout(() => {
          deleteToast(t);
        }, t.duration * 1000);
    };

    const deleteToast = function (t) {
      t.isShown = false;
      setTimeout(() => {
        toasts.value.splice(toasts.value.indexOf(t), 1);
      }, 300);
    };

    return { toasts, addToast, deleteToast, getStyle };
  },
};
</script>
