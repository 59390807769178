import vLogIn from "@/views/account/vLogIn.vue";

export default [
	{
		path: "/login",
		name: "login",
		component: vLogIn,
		meta: { title: "Log In", auth: false, roles: null },
	},
	{
		path: "/passwordrecovery",
		name: "passwordRecovery",
		component: () => import(/* webpackChunkName: "account" */ "../views/account/vPasswordRecovery.vue"),
		meta: { title: "Password Recovery", auth: false, roles: null },
	},
	{
		path: "/passwordreset/:code",
		name: "passwordReset",
		component: () => import(/* webpackChunkName: "account" */ "../views/account/vPasswordReset.vue"),
		meta: { title: "Password Reset", auth: false, roles: null },
	},
	{
		path: "/confirmemail/:code",
		name: "confirmemail",
		component: () => import(/* webpackChunkName: "account" */ "../views/account/vPasswordReset.vue"),
		meta: { title: "Password Reset", auth: false, roles: null },
	},
	{
		path: "/profile",
		name: "profile",
		component: () => import(/* webpackChunkName: "account" */ "../views/account/vProfile.vue"),
		meta: { title: "Profile", auth: true, roles: null },
	},
];
