import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import * as _config from "@/config";
import accountRoutes from "@/router/accountRoutes";
import adminRoutes from "@/router/adminRoutes";
import clockworkRoutes from "@/router/clockworkRoutes";
import clientRoutes from "@/router/clientRoutes";
import messageRoutes from "@/router/messageRoutes";
import reportRoutes from "@/router/reportRoutes";
import userRoutes from "@/router/userRoutes";
import managerRoutes from "@/router/managerRoutes";
//import { CfRouteConfig } from "@/shims-vue";

const baseRoutes = [
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "work" */ "../views/tickets/vTicketList.vue"),
    meta: { title: "Tickets", auth: true, roles: null },
  },
  {
    path: "/welcome",
    name: "welcome",
    component: () => import(/* webpackChunkName: "work" */ "../views/tickets/vTicketList.vue"),
    meta: { title: "Tickets", auth: true, roles: null },
  },
  {
    path: "/about",
    name: "about",
    component: () => import(/* webpackChunkName: "misc" */ "../views/vAbout.vue"),
    meta: { title: "About", auth: true, roles: [0] },
  },
  {
    path: "/notauthorized",
    name: "notauthorized",
    component: () => import(/* webpackChunkName: "misc" */ "@/views/vNotAuthorized.vue"),
    meta: { title: "Not Authorized", auth: false, roles: null },
  },
  {
    path: "/badroute",
    name: "badroute",
    component: () => import(/* webpackChunkName: "misc" */ "@/views/vNotFound.vue"),
    meta: { title: "Not Found", auth: false, roles: null },
  },
  {
    path: "/update",
    name: "update",
    component: () => import(/* webpackChunkName: "misc" */ "@/views/vUpdate.vue"),
    meta: { auth: false },
  },
  {
    path: "/:catchAll(.*)",
    name: "catchall",
    component: () => import(/* webpackChunkName: "misc" */ "@/views/vNotFound.vue"),
    meta: { title: "Not Found", auth: false, roles: null },
  },
];

const routes = baseRoutes.concat(adminRoutes).concat(accountRoutes).concat(clientRoutes).concat(clockworkRoutes).concat(messageRoutes).concat(reportRoutes).concat(userRoutes).concat(managerRoutes);

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

let reRoutePath = "";
router.beforeEach((to, from, next) => {
  store.dispatch("isAuthenticated").then((isLoggedIn) => {
    //cast the standard meta to custom typescript one that knows about the roles collection
    let cfMeta = to.meta;
    //If the Route auth meta data is true this url requires authentication
    if (cfMeta.auth) {
      //If they are not logged in then save the requested URL then redirect to Login
      if (!isLoggedIn) {
        reRoutePath = to.path;
        return next("/login");
      }
      //If they are logged in, but have insufficient privileges redirect to not authorized screen
      if (cfMeta.roles != null) {
        let canView = false;
        let userRole = store.state.user.role;
        cfMeta.roles.forEach((r) => {
          //Starter app roles are simple int, with higher the number the more priveleges
          if (userRole >= r) canView = true;
        });
        if (!canView) return next("/notauthorized");
      }
      //We know the are logged in, and have sufficient privileges
      //Now check for a previous navigation
      if (reRoutePath.length > 0) {
        //we  found one, reset the variable and take them there
        const path = reRoutePath;
        reRoutePath = "";
        return next(path);
      }
      //Finally, if we got here just take them to the requested URL
      return next();
    } else {
      //check to see if we have a reRoute path, but only if we are not going to log in/out...
      if (reRoutePath.length > 0 && to.path != "/logout" && to.path != "/login") {
        //we  found one, reset the variable and take them there
        const path = reRoutePath;
        reRoutePath = "";
        return next(path);
      }
      //The requested URL does not require authentication
      return next();
    }
  });
});

router.afterEach((to, from) => {
  let title = _config.appTitle ? _config.appTitle : "";
  title += to.meta ? (to.meta.title ? ":" + to.meta.title : "") : "";
  document.title = title;
  let el = document.getElementById("side-bar");
  if (el) el.classList.remove("show");
  let elm = document.getElementById("nav-user-menu");
  if (elm) elm.classList.remove("show");
  let newVersion = sessionStorage.getItem("cw.vue.newVersion");
  if (newVersion === "true") {
    console.info("new version found, updating...");
    setTimeout(() => {
      sessionStorage.removeItem("cw.vue.newVersion");
      window.location.reload();
    }, 100);
  }
});

export default router;
