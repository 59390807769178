export default [
  {
    path: "/projects/:id?",
    name: "projects",
    component: () => import(/* webpackChunkName: "work" */ "../views/projects/vProjectList.vue"),
    meta: { title: "Projects", auth: true, roles: [0, 10, 20] },
  },
  {
    path: "/areas/:id?",
    name: "areas",
    component: () => import(/* webpackChunkName: "work" */ "../views/areas/vAreaList.vue"),
    meta: { title: "Areas", auth: true, roles: [0, 10, 20] },
  },
  {
    path: "/tasks/:id?",
    name: "tasks",
    component: () => import(/* webpackChunkName: "work" */ "../views/tasks/vTaskList.vue"),
    meta: { title: "Tasks", auth: true, roles: [0, 10, 20] },
  },
  {
    path: "/tickets/:id?",
    name: "tickets",
    component: () => import(/* webpackChunkName: "work" */ "../views/tickets/vTicketList.vue"),
    meta: { title: "Tickets", auth: true, roles: [0, 10, 20] },
  },
  {
    path: "/expenses/:id?",
    name: "expenses",
    component: () => import(/* webpackChunkName: "work" */ "../views/expenses/vExpenseList.vue"),
    meta: { title: "Expenses", auth: true, roles: [0, 10, 20] },
  },
  
];
