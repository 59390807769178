import * as _core from "./coreService";
import * as _config from "@/config";
import * as _utility from "@/utility";

export function deleteDocumentById(documentId) {
  return new Promise((resolve) => {
    let url = _config.urlDocumentService + "DeleteDocumentById/";
    let data = { DocumentId: documentId };
    _core.fetchPost(url, data, true).then((response) => {
      resolve(response);
    });
  });
}

export function getDocumentsByLinkId(linkId) {
  console.info("getDocumentsByLinkId");
  return new Promise((resolve) => {
    let url = _config.urlDocumentService + "GetDocumentsByLinkId/";
    let data = { LinkId: linkId };
    _core.fetchGet(url, data, true).then((response) => {
      resolve(response);
    });
  });
}

export function getDocumentBytes(documentId) {
  return new Promise((resolve) => {
    let url = _config.urlDocumentService + "GetDocumentBytes/";
    let data = { DocumentId: documentId };
    _core.fetchGet(url, data, true).then((response) => {
      if (response.success === true) {
        _utility.saveFile(response.fileName, response.fileBytes, response.contentType);
      }
      resolve(true);
    });
  });
}

export function getDocumentUrl(documentId) {
  return new Promise((resolve) => {
    let url = _config.urlDocumentService + "GetDocumentUrl/";
    let data = { DocumentId: documentId };
    _core.fetchGet(url, data, true).then((response) => {
      resolve(response);
    });
  });
}
