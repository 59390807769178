<template>
  <div class="component-status" :class="{ active: activeTasks.length > 0 }">
    <!-- <button class="btn btn-link" @click="toggleShowDismissed(false)" v-show="showDismissed">Remove hidden</button> -->
    <div v-for="task in activeTasks" :key="task.TaskStatusId">
      <!-- <div class="component-status"> -->
      <div class="d-inline me-2" :class="{ 'text-danger': !task.success }">
        <span v-show="!task.IsComplete">{{ task.Message }} Step {{ task.CurrentStep }} of {{ task.TotalSteps }} </span>
        <span v-show="task.IsComplete">
          <span v-show="task.DocumentId != null">
            <button class="btn btn-link" @click="downloadFile(task.DocumentId)">{{ task.Message }}</button> Ready!
          </span>
          <span v-show="task.DocumentId === null">
            {{ task.Message }}
          </span>
        </span>
        {{ filters.toFormattedDateTime(task.UpdatedDate) }}
      </div>
      <div class="spinner-grow text-warning spinner-grow-sm" role="status" v-show="!task.IsComplete"></div>
      <i class="bi bi-check-circle-fill ms-2" v-show="task.IsComplete && task.success"></i>
      <i class="bi bi-exclamation-octagon-fill ms-2" v-show="task.IsComplete && !task.success"></i>
      <i class="bi bi-eye-slash ms-2" v-show="!task.IsDismissed" @click="toggleDismissed(task)" title="Hide"></i>
      <i class="bi bi-eye ms-2" v-show="task.IsDismissed" @click="toggleDismissed(task)" title="Show"></i>
      <i class="bi bi-x-circle ms-2" v-show="task.IsComplete" @click="deleteTask(task)" title="Remove task from list"></i>
      <i class="bi bi-x-circle hidden ms-2" v-show="!task.IsComplete"></i>
    </div>
  </div>
</template>

<script>
import { computed, inject, onMounted, reactive, ref, watch } from "vue";
import * as _core from "@/services/coreService";
import * as _config from "@/config";
import * as _utility from "@/utility";
import filters from "@/utility/filters";

import * as _documentService from "@/services/documentService";

export default {
  name: "cTaskStatusAlert",
  components: {},
  props: {},
  setup(props, context) {
    c8.eventBus.on("taskStatusUpdate", (e) => updateTask(e));
    c8.eventBus.on("taskTogglDismissed", (e) => toggleShowDismissed(e));
    const store = inject("store");

    const showDismissed = ref(false);
    const tasks = ref([]);

    const load = () => {
      //Disable Status Alerts for now
      //console.info("cTaskStatusAlert|load|token:", store.state.user.token);
      // if (store.state.user.token == null) return;
      // let url = _config.urlTaskService + "GetActiveTasks/";
      // let data = {};
      // _core.fetchGet(url, data, false).then((response) => {
      //   response.tasks.forEach((t) => {
      //     updateTask(t);
      //   });
      // });
    };

    function checkStatus() {
      let tasksToUpdate = tasks.value.filter((e) => e.IsComplete === false);
      if (tasksToUpdate.length === 0) return;
      let data = { TaskStatusIds: [] };
      tasksToUpdate.forEach((t) => {
        data.TaskStatusIds.push(t.TaskStatusId);
      });
      //console.info("checkStatus fired!", data);
      let url = _config.urlTaskService + "GetTaskStatus/";
      _core.fetchPost(url, data, false).then((response) => {
        response.tasks.forEach((t) => {
          updateTask(t);
        });
      });
    }

    const deleteTask = (task) => {
      task.IsDeleted = true;
    };

    const downloadFile = function (documentId) {
      _documentService.getDocumentBytes(documentId).then((response) => {});
    };

    const timer = setInterval(() => {
      checkStatus();
    }, 5000);

    const toggleDismissed = (task) => {
      task.IsDismissed = !task.IsDismissed;
    };

    const toggleShowDismissed = function (show) {
      if (show === true || show === false) {
        showDismissed.value = show;
      } else {
        showDismissed.value = !showDismissed.value;
      }
      //console.info("toggleShowDismissed", showDismissed.value);
    };

    const updateTask = (e) => {
      //console.info("cTaskStatusAlert|updateTask fired:", e);
      let task = tasks.value.find((t) => t.TaskStatusId === e.TaskStatusId);
      if (task) {
        _utility.mapper(e, task, false); //<< false means don't parse date while mapping
        //if a task is completed and has a document then make sure to
        //un-dismiss it so the user knows it's done.
        if (e.IsComplete && e.DocumentId !== null) task.IsDismissed = false;
        //automatically dismiss tasks that don't have a document
        if (e.IsComplete && e.DocumentId === null && e.success)
          setTimeout(() => {
            task.IsDismissed = true;
          }, 3000);
      } else {
        tasks.value.push({ ...e, IsDismissed: false, IsDeleted: false });
      }
      let nonCompletedTasks = tasks.value.filter((t) => {
        return !t.IsComplete && !t.IsDeleted;
      });
      c8.eventBus.emit("taskStatusUpdateCount", nonCompletedTasks.length);
    };

    const activeTasks = computed(() => {
      return tasks.value.filter((t) => {
        return (showDismissed.value && !t.IsDeleted) || (!t.IsDismissed && !t.IsDeleted);
      });
    });

    const userToken = computed(() => {
      return store.state.user.token;
    });

    watch(userToken, (newValue, oldValue) => {
      load();
    });

    onMounted(() => {
      load();
    });
    return { activeTasks, deleteTask, toggleDismissed, downloadFile, filters, showDismissed, tasks, toggleShowDismissed };
  },
};
</script>

<style>
.component-status {
  position: fixed;
  bottom: var(--footerHeight);
  width: 50%;
  left: 0;
  max-height: 0px;
  opacity: 0;
  background-color: var(--bs-light);
  color: var(--bs-gray-dark);
  padding: 10px;
  border-radius: 3px;
  text-align: right;
  box-shadow: 0.2rem -0.2rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  z-index: 10000;
  transition-property: max-height;
  transition-duration: 1.5s;
  transition-delay: 0.2s;
}
.component-status.active {
  max-height: 1000px;
  opacity: 100;

  transition-property: max-height;
  transition-duration: 1.5s;
  transition-delay: 0.2s;
}
.component-status .btn.btn-link {
  line-height: 1;
  position: relative;
  top: -3px;
}
.component-status .bi-check-circle-fill {
  color: var(--bs-success);
}
.component-status .bi-exclamation-octagon-fill {
  color: var(--bs-danger);
}
i.bi.bi-x-circle.hidden {
  color: var(--bs-light);
}
@media (max-width: 575.98px) {
  .component-status {
    right: 10px;
    left: 10px;
    width: auto;
  }
}
</style>
