export default [
  {
    path: "/adminreports",
    name: "adminreports",
    component: () => import(/* webpackChunkName: "report" */ "../views/reports/vAdminReports.vue"),
    meta: { title: "Admin Reports", auth: true, roles: [10, 20] },
  },
  {
    path: "/userticketreport",
    name: "userticketreport",
    component: () => import(/* webpackChunkName: "report" */ "../views/reports/vUserTicketReport.vue"),
    meta: { title: "Ticket Report", auth: true, roles: [0, 10, 20] },
  },
];
