<template>
  <div class="card medium">
    <div class="card-header">Log In</div>
    <div class="card-body">
      <div class="card-text">
        <form>
          <div class="mb-3">
            <cValidatedInput v-model="vv.email" type="text" label="Email" placeholder="name@example.com" />
          </div>
          <div class="mb-3">
            <cValidatedInput v-model="vv.password" type="password" label="Password" placeholder="" />
          </div>
          <div class="mb-3">
            <div class="form-check ms-3">
              <input class="form-check-input" type="checkbox" v-model="vv.rememberMe.$model" id="chkRememberMe" />
              <label class="form-check-label" for="chkRememberMe"> Remember Me </label>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="card-footer">
      <div class="row">
        <div class="col">
          <a href="/passwordrecovery" class="align-middle">Forgot Password</a>
        </div>
        <div class="col text-end">
          <button type="button" class="btn btn-primary" @click="logIn" :disabled="!formIsValid">Login</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, watch } from "vue";
import router from "@/router";

import * as _securityService from "@/services/securityService";
//https://vuelidate.js.org/#sub-builtin-validators
import { required, email } from "@vuelidate/validators";
import * as _validation from "@/components/Library/Validation/validation";
import cValidatedInput from "@/components/Library/Input/cValidatedInput";

export default {
  components: { cValidatedInput },
  setup() {
    const form = reactive({
      email: "",
      password: "",
      rememberMe: false,
    });

    const routerInstance = router;
    const logIn = () => {
      vv.value.$touch();
      if (vv.value.$invalid) return;
      _securityService.logIn(form.email, form.password, form.rememberMe).then((response) => {
        if (response.success) {
          routerInstance.push("/tickets");
        }
      });
    };

    //Validation support..
    const vv = _validation.getValidator(form, {
      email: { required, email },
      password: { required },
      rememberMe: { required },
    });
    const formIsValid = ref(true);
    watch(vv, (newValue, oldValue) => {
      if (!newValue) formIsValid.value = true;
      formIsValid.value = !newValue.$invalid;
    });
    return { logIn, vv, formIsValid, form };
  },
};
</script>

<style></style>
