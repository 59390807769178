let apiUrl = "../api/";
if (location.port) {
  //apiUrl = "https://localhost:44335/api/";
  apiUrl = "https://localhost:7296/api/";
}
//Live endpoint!!!
//apiUrl = "https://code-clockwork.azurewebsites.net/api/";
//apiUrl = "https://code-clockwork-dev.azurewebsites.net/api/";

export const appTitle = "Clockwork";

//Local Storage keys
export const storageBaseKey = "cw.vue";
export const storageUserKey = storageBaseKey + ".user";
export const storageAppIdKey = storageBaseKey + ".appid";

//System message defaults
export const defaultMessageX = "left";
export const defaultMessageY = "top";
export const defaultMessageTimeout = 6000;

//Service Endpoints
export const urlDocumentService = apiUrl + "clockdocument/";
export const urlDocumentIntelligenceService = apiUrl + "clockdocumentintelligence/";
export const urlInvoicingService = apiUrl + "clockinvoicing/";
export const urlMessageService = apiUrl + "message/";
export const urlReportService = apiUrl + "clockreport/";
export const urlSecurityService = apiUrl + "clocksecurity/";
export const urlClockworkService = apiUrl + "clockwork/";
export const urlTaskService = apiUrl + "taskstatus/";
export const urlUserService = apiUrl + "user/";

