<template>
  <div class="task-icon" @click="showDimsisedTasks" :class="{ active: bigSize }">
    <i class="bi bi-hourglass-split"></i>
    <div v-show="activeTasks > 0" class="task-count">{{ activeTasks }}</div>
    <div v-show="activeTasks > 0" class="spinner-grow text-warning task-count" role="status"></div>
  </div>
</template>

<script>
import { computed, inject, onMounted, reactive, ref, watch } from "vue";

export default {
  name: "cTaskStatusIcon",
  components: {},
  props: {},
  setup(props, context) {
    c8.eventBus.on("taskStatusUpdateCount", (e) => updateCount(e));
    const isBig = ref(false);
    const activeTasks = ref(0);
    const showDimsisedTasks = () => {
      isBig.value = !isBig.value;
      c8.eventBus.emit("taskTogglDismissed");
    };
    const updateCount = (c) => {
      activeTasks.value = c;
    };

    const bigSize = computed(() => {
      return activeTasks.value > 0 || isBig.value;
    });
    onMounted(() => {});
    return { activeTasks, bigSize, showDimsisedTasks };
  },
};
</script>

<style>
.task-icon {
  position: fixed;
  bottom: 0;
  left: 10px;
  z-index: 10002;
  transition-property: font-size;
  transition-duration: 0.5s;
  transition-delay: 0.2s;
}
.task-icon.active {
  font-size: 1.5rem;
  transition-property: font-size;
  transition-duration: 0.5s;
  transition-delay: 0.2s;
}
.task-icon.active .task-count {
  font-size: 1.2rem;
  transition-property: font-size;
  transition-duration: 0.5s;
  transition-delay: 0.2s;
}
.task-icon.active .spinner-grow {
  height: 25px !important;
  width: 25px !important;
  top: -7px;
  right: -12px;
}
.task-count {
  position: absolute;
  top: -5px;
  right: -9px;
  color: var(--bs-gray-dark);
  background-color: var(--bs-light);
  border-radius: 10px;
  height: 18px !important;
  width: 18px !important;
  line-height: 18px;
  text-align: center;
}
</style>
