<template>
	<cLogIn />
</template>

<script>
// @ is an alias to /src
import cLogIn from "@/components/Account/cLogIn.vue";

export default {
	name: "vLogIn",
	components: {
		cLogIn,
	},
};
</script>
<style scoped></style>
