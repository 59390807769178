<template>
  <nav class="navbar navbar-expand-lg fixed-top navbar-dark bg-primary">
    <div class="container-fluid">
      <button class="navbar-toggler" type="button" aria-label="Toggle navigation" @click="toggleSidebar">
        <span class="navbar-toggler-icon"></span>
      </button>
      <router-link class="navbar-brand title-left" to="/">
        <i class="bi bi-clock text-white"></i>
        {{ appTitle }} {{ pageTitle }} <span class="text-bg-warning">{{ environment }}</span>
      </router-link>
      <ul class="nav d-none d-sm-flex">
        <li class="nav-item" v-if="store.state.user.email">
          <a class="nav-link dropdown-toggle text-light" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">{{ store.state.user.email }}</a>
          <ul id="nav-user-menu" class="dropdown-menu">
            <li>
              <router-link to="/profile" class="dropdown-item"><i class="bi bi-person"></i> Profile</router-link>
            </li>
            <li>
              <a href="#" class="dropdown-item" @click="logOut"><i class="bi bi-unlock"></i> Log Out</a>
            </li>
          </ul>
        </li>
        <li class="nav-item" v-if="!store.state.user.email">
          <router-link to="/login" class="nav-link text-light"><i class="bi bi-lock"></i> Log In</router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { computed, ref, inject } from "vue";
import router from "@/router";
import * as _config from "@/config";
import * as _securityService from "@/services/securityService";

export default {
  name: "cNav",
  setup() {
    const store = inject("store");
    function toggleSidebar() {
      c8.toggleClassById("side-bar", "show");
    }

    const currentRoute = router;
    const logOut = function () {
      _securityService.logOut().then((response) => {
        if (response.success) {
          store.dispatch("logOut").then((r) => {
            currentRoute.push("/login");
          });
        }
      });
    };

    const appTitle = computed(() => {
      return _config.appTitle;
    });
    const environment = computed(() => {
      let e = store.state.user.environment;
      return e ? (e.toLowerCase() == "production" ? "" : `(${e})`) : "";
    });
    const pageTitle = computed(() => {
      return currentRoute.currentRoute.value.meta ? currentRoute.currentRoute.value.meta.title : "";
    });
    return { appTitle, currentRoute, environment, pageTitle, toggleSidebar, logOut, store };
  },
};
</script>

<style>
img.logo {
  height: 40px;
  /* margin: -5px; */
}
.navbar-brand.title-left {
  position: absolute;
  left: 80px;
}
</style>
