function mapper(source, destination, mapForDateBinding) {
  if (mapForDateBinding === undefined) mapForDateBinding = true;
  if (typeof destination == "undefined" || typeof destination != typeof source) {
    if (typeof source == "object") destination = {};
    if (typeof source == "array") destination = [];
    if (typeof source == "date") destination = new Date();
    if (typeof source == "string") destination = "";
    if (typeof source == "number") destination = 0;
  }
  //console.log("mapper|source|destination", source, destination);
  let key;
  for (key in source) {
    //console.log(key, typeof source[key]);
    if (destination[key] !== undefined) {
      let value = source[key];
      if (!value || typeof value == "array" || typeof value == "object") continue;
      //if we are binding to a date picker and this is a date with the time zone (2018-05-14T00:00:00)
      // we need to string the time component
      if (mapForDateBinding && isNaN(value) && value.split("-").length === 3 && !isNaN(Date.parse(value))) {
        //console.info("mapper found date:", value)
        value = value.substr(0, value.indexOf("T"));
      }
      destination[key] = value;
    }
  }
}

export default mapper;
