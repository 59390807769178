import mapper from "@/utility/mapper";
import * as fileSaver from "file-saver";
import store from "@/store";
import * as _config from "@/config";

export const emptyGuid = "00000000-0000-0000-0000-000000000000";

export function newGuid() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function b64toBlob(b64Data, contentType, sliceSize) {
  //console.log("b64toBlob: " + contentType);
  //console.log("b64toBlob: " + b64Data);
  contentType = contentType || "";
  sliceSize = sliceSize || 512;
  var byteCharacters = atob(b64Data);
  var byteArrays = [];
  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);
    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

export function getUserTZOffset() {
  //We need to negate it for proper offset calcs
  return -new Date().getTimezoneOffset();
}

export function isInRole(allowedRoles) {
  if (allowedRoles != null) {
    let canView = false;
    let ur = store.getters.userRole;
    allowedRoles.forEach((r) => {
      if (ur === r) canView = true;
    });
    /* If the user can be assigned multiple roles then we
    would need to iterate them all..
    let userRoles = store.getters.userRoles;
		allowedRoles.forEach((r) => {
			userRoles.forEach((ur) => {
				//console.log("isInRole:", r, ur);
				if (ur === r) canView = true;
			});
		}); */
    return canView;
  }
  return true;
}

export function mimeTypeToFileExtension(mimeType) {
  //https://stackoverflow.com/questions/4212861/what-is-a-correct-mime-type-for-docx-pptx-etc

  //application/pdf
  if (mimeType.indexOf("pdf") > -1) return "pdf";
  //application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
  if (mimeType.indexOf("spreadsheetml.sheet") > -1) return "xlsx";
  if (mimeType.indexOf("xlsx") > -1) return "xlsx";
  //application/vnd.ms-excel
  if (mimeType.indexOf("excel") > -1) return "xls";
}
export function removeSearch(control) {
  let k = _config.storageBaseKey + "." + control;
  console.log("removeSearch:", k);
  localStorage.removeItem(k);
  sessionStorage.removeItem(k);
}
export function retrieveSearch(control) {
  let k = _config.storageBaseKey + "." + control;
  let l = localStorage.getItem(k);
  let s = sessionStorage.getItem(k);
  return l && l != "null" ? l : s && s != "null" ? s : "";
}
export function saveFile(fileName, fileBytes, mimeType) {
  let decodedFile = b64toBlob(fileBytes, mimeType, 512);
  let blob = new Blob([decodedFile], {
    type: mimeTypeToFileExtension(mimeType),
  });
  fileSaver.saveAs(blob, fileName);
}
export function setFocus(elementId, delay) {
  if (delay === undefined || delay === null) delay = 0;
  setTimeout(() => {
    let el = document.getElementById(elementId);
    if (el) el.focus();
    else console.error("Unable to find elementId:", elementId);
  }, delay);
}
export function storeSearch(control, value, persist) {
  if (!persist) persist = false;
  //console.info("storeSearch|persist:", persist);
  let k = _config.storageBaseKey + "." + control;
  if (persist) {
    localStorage.setItem(k, value);
    sessionStorage.removeItem(k);
  } else {
    sessionStorage.setItem(k, value);
    localStorage.removeItem(k);
  }
}
export function toTitleCaseObject(obj, excludeProperties) {
  if (!excludeProperties) excludeProperties = [];
  var newObject = {};
  for (var key in obj) {
    //Skip any properties in the exclude list
    if (excludeProperties.find((p) => p.toUpperCase() === key.toUpperCase())) continue;
    if (obj.hasOwnProperty(key)) {
      var newKey = key.charAt(0).toUpperCase() + key.slice(1);
      if (obj[key] === null) {
        //We have to specifically target the object null,
        //otherwise it will be returned as an empty object
        newObject[newKey] = null;
      } else if (obj[key] instanceof Array) {
        newObject[newKey] = obj[key];
      } else if (typeof obj[key] === "object") {
        //Recursively search an object
        newObject[newKey] = toTitleCaseObject(obj[key]);
      } else {
        newObject[newKey] = obj[key];
      }
    }
  }
  return newObject;
}

export { mapper };
