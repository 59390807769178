import dayjs from "dayjs";
import * as _utility from "@/utility";
const utcOffset = _utility.getUserTZOffset();

export default {
  toDayOfWeek(value: any, offsetForUTC = false) {
    if (!value || value.length == 0) return "";
    return offsetForUTC ? dayjs(value).add(utcOffset, "minutes").format("ddd") : dayjs(value).format("ddd");
  },
  toHoursMinutes(value: any) {
    //convert minutes  to hours and minutes
    if (!value || value.length == 0) return "";
    let hours = Math.floor(value / 60);
    let minutes = value % 60;
    //pad minutes with leading zero
    let m = minutes < 10 ? "0" + minutes : minutes;
    return hours > 0 ? `${hours}h ${m}m` : `${m}m`;
  },
  toTimeAgo(value: any) {
    if (!value || value.length == 0) return "";
    let d = dayjs(value).add(utcOffset, "minutes");
    let c = dayjs();
    if (c.diff(d, "year") > 1) return "over a year";
    if (c.diff(d, "month") > 0) return `${c.diff(d, "month")} months ago`;
    if (c.diff(d, "week") > 0) return `${c.diff(d, "week")} weeks ago`;
    if (c.diff(d, "day") > 0) return `${c.diff(d, "day")} days ago`;
    if (c.diff(d, "hour") > 0) return `${c.diff(d, "hour")} hours ago`;
    if (c.diff(d, "minute") > 5) return `${c.diff(d, "minute")} minutes ago`;
    return "just now";
  },
  toFormattedDate(value: any, offsetForUTC = false) {
    if (!value || value.length == 0) return "";
    return offsetForUTC ? dayjs(value).add(utcOffset, "minutes").format("MM/DD/YYYY") : dayjs(value).format("MM/DD/YYYY");
  },
  toFormattedDateYYYYMMDD(value: any, offsetForUTC = false) {
    if (!value || value.length == 0) return "";
    return offsetForUTC ? dayjs(value).add(utcOffset, "minutes").format("YYYY-MM-DD") : dayjs(value).format("YYYY-MM-DD");
  },
  toFormattedDateTime(value: any, offsetForUTC = false, wrap = false) {
    if (!value || value.length == 0) return "";
    const d = offsetForUTC ? dayjs(value).add(utcOffset, "minutes") : dayjs(value);
    return wrap ? d.format("MM/DD/YYYY<br>h:mm:ss a") : d.format("MM/DD/YYYY h:mm:ss a");
  },
  toFormattedDayTime(value: any, offsetForUTC = false, wrap = false) {
    if (!value || value.length == 0) return "";
    const d = offsetForUTC ? dayjs(value).add(utcOffset, "minutes") : dayjs(value);
    return wrap ? d.format("ddd MM/DD<br>HH:mm") : d.format("ddd MM/DD HH:mm");
  },
  toFormattedDay(value: any, offsetForUTC = false, wrap = false) {
    if (!value || value.length == 0) return "";
    const d = offsetForUTC ? dayjs(value).add(utcOffset, "minutes") : dayjs(value);
    return wrap ? d.format("ddd MM/DD") : d.format("ddd MM/DD ");
  },
  toFormattedDayDate(value: any, offsetForUTC = false, wrap = false) {
    if (!value || value.length == 0) return "";
    return offsetForUTC ? dayjs(value).add(utcOffset, "minutes").format("ddd MM/DD") : dayjs(value).format("ddd MM/DD");
  },
  toFormattedTime(value: any, offsetForUTC = false, wrap = false) {
    if (!value || value.length == 0) return "";
    const d = offsetForUTC ? dayjs(value).add(utcOffset, "minutes") : dayjs(value);
    return wrap ? d.format("HH:mm") : d.format("HH:mm");
  },
  boolToYesNo(value: any) {
    if (value == null || value.length === 0) return "-";
    return value ? "Yes" : "No";
  },
  boolToActive(value: any) {
    if (value == null || value.length === 0) return "-";
    return value ? "Active" : "Inactive";
  },
  boolToDisabled(value: any) {
    if (value == null || value.length === 0) return "-";
    return value ? "Disabled" : "Active";
  },
  toNumberString(value: any) {
    if (value == null || value.length === 0) return "-";
    if (isNaN(value)) return value;
    return parseFloat(value).toLocaleString();
  },
  padLeft(value: any, length: number, padChar: string) {
    if (value == null || value.length === 0) return "".padStart(length, padChar);
    return value.toString().padStart(length, padChar);
  },
  toNumberStringDecimalPoint(value: any, decimalPlaces = 2) {
    if (value == null || value.length === 0) return "-";
    if (isNaN(value)) return value;
    return parseFloat(value).toFixed(decimalPlaces).toLocaleString();
  },
};
