<template>
  <footer class="footer bg-light fixed-bottom">
    <span class="text-muted">© All rights reserved. v{{ version }}</span>
  </footer>
</template>

<script>
import { ref, getCurrentInstance } from "vue";

export default {
  name: "cFooter",
  components: {},
  setup() {
    const internalInstance = getCurrentInstance();
    const version = internalInstance.appContext.config.globalProperties.version;
    const showDimsisedTasks = () => {
      c8.eventBus.emit("taskTogglDismissed");
    };
    return { showDimsisedTasks, version };
  },
};
</script>
<style>
.footer {
  z-index: 10001;
}
</style>
