//https://dev.to/aaronksaunders/using-vue3-and-vuelidate-for-forms-and-form-validation-4aa1
import { useVuelidate } from "@vuelidate/core";

export function getValidator(form, rules) {
  return useVuelidate(rules, form);
}

export function validationClass(property) {
  return property.$dirty && property.$invalid ? "is-invalid" : "";
}

//https://vuelidate.js.org/#sub-builtin-validators
export function validationErrorMessage(property) {
  if (property.$dirty && !property.$error) return "";
  //console.log("validator message fire...");
  let message = "";
  property.$errors.forEach((err) => {
    switch (err.$validator) {
      case "complexPassword":
        message += "must be least 8 characters, one number, one letter, and one special character, ";
        break;
      case "currencyValidation":
        message += "invalid money input, ";
        break;
      case "dateAfter":
        message += "End Date must be after Start Date, ";
        break;
      case "dateAfterOrEqual":
        message += "End Date must be equal to or after Start Date, ";
        break;
      case "requiredSelect":
        message += "Required, ";
        break;
      case "sameAs":
        message += "The two values do not match, ";
        break;
      case "sameAsPassword":
        message += "New Password and Confirm Password do not match, ";
        break;
      default:
        message += err.$message.replace("Value", "") + ", ";
    }
  });
  return message.length === 0 ? "" : message.substr(0, message.length - 2);
}

//Support direct control over validate messages..
export function setErrorMessage(elementId, message) {
  let el = document.getElementById(elementId);
  if (!el) {
    console.error("setErrorMessage:requested element " + elementId + " not found!");
    return;
  }
  el.classList.add("is-invalid");
  let elMessage = el.previousElementSibling;
  if (elMessage) {
    elMessage.innerHTML = message;
    let elLabel = elMessage.previousElementSibling;
    if (elLabel) elLabel.classList.add("is-invalid");
  }
}

export function clearErrorMessage(elementId) {
  let el = document.getElementById(elementId);
  if (!el) {
    console.error("setErrorMessage:requested element " + elementId + " not found!");
    return;
  }
  el.classList.remove("is-invalid");
  let elMessage = el.previousElementSibling;
  if (elMessage) {
    elMessage.innerHTML = "";
    let elLabel = elMessage.previousElementSibling;
    if (elLabel) elLabel.classList.remove("is-invalid");
  }
}
